import React from 'react'
import { Modal } from 'react-bootstrap'

const UIModal = ({ show, onHide, children, size = "md", header = null, footer = null, closeButon = true }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size={size}
            centered
        >
            <Modal.Header
                closeButton={closeButon}
                className='pb-0 pt-2 border-0'
            >
                {header}
            </Modal.Header>

            <Modal.Body>
                {children}
            </Modal.Body>

            <Modal.Footer className='border-0 p-0'>
                {footer}
            </Modal.Footer>
        </Modal>
    )
}

export default UIModal