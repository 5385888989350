import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import LoginWrap from "./style";
import LoginForm from "../../Reuseable/Forms/LoginForm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = ({ props, isFirstTimeLoggin }) => {
  const isLoggedIn = useSelector(state => state.Auth.isLoggedIn)
  const navigate = useNavigate()

  useEffect(() => {
    isLoggedIn && navigate('/home/dashBoard')
  }, [isLoggedIn, navigate])

  return (
    <LoginWrap>
      <section className="loginPageContent" >
        <Container className="container-width bg-white">
          <LoginForm props={props} isFirstTimeLoggin={isFirstTimeLoggin} />
        </Container>
      </section>
    </LoginWrap>
  );
};

export default Login;
