import Styled from 'styled-components';

const UICardView = Styled.div`
* {
    white-space: pre-wrap;
}
.ui-card-dashboard{
    font-family: 'Montserrat', sans-serif !important;
}
.full-card-row{
    justify-content: space-around;
    box-shadow: 0px 0px 8px #0000003b;
    border-radius: 5px;
    opacity: 1;
}
.app-id-row{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 10px; 
}
.snackbar-alert {
    padding-top: 100px;
}
.card-label {
    font-size: 1.4rem;
    font-weight: 600;
}
.loan-amount{
    text-align: end;
}
.loan-amount-color {
    color: var(--orange-color);
}
.max-bid-text {
    font: normal normal 500 16/19px Montserrat;
    font-weight: 500;
}
.max-bid-input {
    flex-basis: 35%;
}
.max-bid-input-control {
    min-width: 80px;
    border: none;
}
.max-bid-button {
    padding: 5px 20px;
    background: var(--orange-color);
    border: none;
    color: white
}
.max-bid-stay-warning {
    color: var(--error-color);
    font-size: 14px;
    font-weight: 500;
    text-align: end;
}
.max-bid-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 14px 8px 14px;
    background: #FCF4E9;
}
.app-head-row {
    --bs-gutter-y: .5rem;
    background: #FCF4E9;
    border-radius: 5px;
}
.avatar{
    display: contents;
}
.t-name{
    font: normal normal 600 24px/29px Montserrat;
    text-align:left;
}
.BidHistory-ul{
    line-height:2;
}
.user-img{
    height: 65px;
    width: 65px;
    opacity: 0.78;
    object-fit: cover;
}
.bid-col{
    padding: 0px 20px 20px 20px !important ;
    opacity: 1;
}
.bid-form-space {
    display: flex;
    justify-content: start;
    align-items: baseline;
}
.bid-recommendataion {
    font: normal normal 500 20px Montserrat;
    flex-shrink: 2;
}
.highest-bidder {
    background: var(--green-color);
    padding: .5em 1em;
    border-radius: 99999px;
    color: white;
    margin-right: 20px;
}
.out-bid {
    background: var(--error-color);
    padding: .5em 1em;
    border-radius: 99999px;
    color: white;
    margin-right: 20px;
}
.your_bid {
    color: #67BE01;
}
.placeBid-form {
    grid-area: place-bid;
}
.error-form {
    grid-area: error-form;
    text-align: center;
}
.max-bid-amount-error {
    color: var(--error-color);
    font-size: 14px;
    text-align: end;
    font-weight: 500;
    padding: 0px 10px 0px 10px;
}
.button-form {
    grid-area: button-form;
    background: var(--orange-color);
    border: none;
    color: white
}
.spinner-size {
    width: 1.2rem;
    height: 1.2rem;
}
.max-bid-add-button {
    background: white;
    border: none;
    color: var(--blue-color);
    font-weight: 500;
    flex-basis: 25%;
}
.form-alignment {
    display: grid;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-areas: 
        "place-bid button-form button-form button-form button-form"
        "error-form ... ... ... ...";    
}
.note-popover-paper {
    width: 250px !important;
    padding: 10px !important;
}
hr {
    margin: 1px;
}
.details{
    display: block !important;
}
.application{
    padding:10px 25px;
    gap: 10px;
}
.app-details-row{
    align-items: center;
    margin-bottom:10px;
    flex-wrap: nowrap;
    text-align:left;
}
.help-icon{
    color: var(--blue-color);
}
.details-p{
    font: normal normal 500 20px/24px Montserrat;
    margin:0;    
    text-align:left;
}
.span-colon{
    display: contents;
    font-weight: 600;
}
.emp-details {
    font: normal normal normal 20px/24px Montserrat;
    padding-top: 10px;  
}
.emp-details {
    font: normal normal normal 20px/24px Montserrat;
    padding-top: 10px;
    text-align:left;
 }
.app-id-span{
    font: normal normal normal 24px/29px Montserrat;
    margin-left:10px;
    text-align:left;;
}
.bid-col{
    font: normal normal normal 16pxpx/29px Montserrat;
    position: relative;
}
.bidding-area {
    min-height: 300px;
    width: auto;
}
.bid-btn{
    width: 90%;
    margin-top:10px;
    border: 1px solid;
    border-radius: 5px;
    font: normal normal 500 24px/29px Montserrat;
    background: var(--orange-color);
}
.hours, .minutes, .seconds {
    border: 1px solid #CECECE;
    padding: 20px 0px;
    border-radius: 5px;
    width: 100%;
    max-height: 80px;
    font-size: 25px;
    font-weight: 600;
}
.count-down-col {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap
}
.label-count{
    width:100%;
    font-size: 14px;
    font-weight: 500;
}
.tot-results, .bid-amount {
    font-size: 24px;
    font-weight: 600;
}
p.bid-credit {
    font-size: 24px;
    font-weight: 500;
}
.notes{
    display: table;
    width: 100%;
    padding: 8px;
    padding-left:10px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 5px 5px;
}
.notes-col{
    display: table-row;
}
.notes-p{
    display: inline;
    padding-left: 12px;
}
.notes-head{
    font: normal normal 600 20px/24px Montserrat;
    padding-left: 10px;
}
.notes-body{
    font: normal normal normal 20px/24px Montserrat;
}
.bold-p{
    font: normal normal 600 24px/29px Montserrat;
}
.bids{
    line-height:2;
    padding:30px;
}
.current-bid {
    color: black;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}
.current-bid-amount{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}
.total-bids {
    font-size: 18px;
    font-weight: 500;
}
.top-three-bids {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.view-all {
    color: var(--orange-color);
    cursor: pointer;
    pointer-event: auto;
}
.total-no-bids {
    font-size: 20px;
}
.hide-hr {
    display: none;
}
.mbl-notes{ 
    display:none;
}

@media only screen and (max-width: 575px){ 
.application {
    padding: 7px;
}
.ui-card-dashboard{
    font-family: 'Montserrat', sans-serif;
    padding: 0 !important;
}
.app-id-span{
    text-align: left;
}
.hours,  .minutes, .seconds {
    padding: 15px 0px;
}
.app-id-row, .card-label, .app-id-span{
    font-size: 20px !important;
    flex-direction: column;
    justify-content: center; 
}
.app-id-row {
    text-align: center;
}
.app-details-row {
    text-align: center;
}
.loan-amount {
    text-align: center;
}
.details-p {
    font-size: 16px;
    text-align:center;
}
.emp-details {
    font-size: 16px;
}
.notes-head{
    display: none;
}
.mbl-notes{ 
    display:unset;
    padding-right:10px;
}
}
/* Table css */
.table {
  border-collapse: separate;
  border-spacing: 0 3px;
  border-radius: 5px;
}
.table-font-size {
  font-size: 14px;
}
th {
    background: #fff;
}
tr {
    background: #F8F7F7;
}
td {
  padding: 4px;
}
td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
/* Closing Table css */
@media only screen and (max-width: 768px){
.app-head-row{
    justify-content: start;
}
.avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.bid-col {
    box-shadow: unset;
    border: none;
    margin-bottom: 10px;
    padding-top: 20px !important;
}
.bid-col::before {
    content: "";
    display: none;
}
.bid-col::after {
    content: "";
    display: none;
}
.hide-hr {
    display: block;
}
}

@media only screen and (min-width: 992px) and (max-width: 1024px){
.label-count {
    font-size: 12px;
}
.hours, .minutes, .seconds {
    padding: 10px 0px;
}
}

@media only screen and (max-width: 1024px){
.bid-col {
    padding: 7px;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#borderLeft {
    right: 210px;
}
.button-form {
    margin-left: 0px;
}
.form-alignment {
    display: grid;
    grid-template-areas: 
        "place-bid place-bid"
        "error-form error-form"
        "button-form button-form";    
}
.bold-p {
    font-size: 20px;
}
}

@media only screen and (min-width: 1025px) and (max-width: 1200px){

.label-count {
    font-size: 12px;
}
.count-down-col {
    padding: 7px;
}
.hours, .minutes, .seconds {
    padding: 10px 0px;
}
}

@media (max-width: 575px) {
    td {
        font-size: 15px;
    }
    .notes-p{
        padding:10px ;
    }
    .bid-form-space {
        flex-direction: column;
        padding: 10px;
        align-items: start;
    }
    .bid-col.col {
        padding: 8px !important;
    }
    td {
        padding: 0 !important;
    }
 }

`;

export default UICardView;