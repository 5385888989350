import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import { Container } from 'react-bootstrap';
import noDataSvg from '../../../assets/images/icons/No data-cuate.svg';

const NoDataFoundWrapper = styled.div
    `

.no-data-msg {
    font: normal normal medium 20px/24px Montserrat;
    letter-spacing: 0px;
    font-weight: 500;
    color: #131313;
}   

.bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #EAF3FA 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1; 
}

    .svg-position {
        margin-top: 180px;
    }
`;


const NoDataFound = () => {
    return (
        <NoDataFoundWrapper>
            <div className='mt-5'>
                <div className='bar'>
                    <div className='no-data-msg'>No data to display</div>
                </div>

                <div className='svg-position d-flex justify-content-center'>
                    <img src={noDataSvg}
                    style={{ width: "500px", objectFit: "cover", minWidth: "100px" }}
                     alt="no Data Found" ></img>
                </div>

            </div>
        </NoDataFoundWrapper>
    )
}

export default NoDataFound