import Styled from "styled-components";

const EditorWrap = Styled.div`
   padding : 20px;
   border : 1px solid #d5d5d5;
   border-radius : 10px;
   margin-bottom : 15px;
   
   .rdw-editor-main{
      overflow : inherit;
   }
`;

export default EditorWrap;