import React from "react"
import moment from "moment"
import Loading from '../../Loading/Loading';
import Tables from '../../Tables';
import jwtDecode from 'jwt-decode';
import UIModal from '..';
import { YourBid } from "./style";

const columns = ['Bid ID', 'Bid Placed', 'Bid Amount', 'Status']
const extra = null
const header = "Bid History"

const ViewAll = ({ show, onHide, viewAllData, viewAllLoading, }) => {

    const profileData = jwtDecode(localStorage.getItem('token'))
    const brokerId = profileData?.broker_id

    const tbody = <tbody>
        {viewAllData?.map((oneData) => {
            return (
                <tr key={oneData.unique_id} className='border-bottom'>
                    <td>{oneData.unique_id}</td>
                    <td>{moment(oneData.bid_date_time).format("h:mm:ss a")}</td>
                    <td className='fw-bold'>{brokerId === oneData.broker_id && <YourBid yourBid={brokerId === oneData.broker_id}>Your Bid -</YourBid>} ${oneData.bid_amount}</td>
                    <td>
                        {(oneData.status === "on-going" && "Ongoing")
                            || (oneData.status === "out-bid" && "Out Bid")
                            || (oneData.status === "won" && "Highest Bidder")}
                    </td>
                </tr>
            )
        })}
    </tbody>

    const tableData = { header, extra, columns, tbody }

    return (
        <UIModal show={show} onHide={onHide} size="lg">
            {viewAllLoading ? <Loading /> : <Tables id="viewAllContent" props={tableData} />}
        </UIModal>
    )
}

export default ViewAll
