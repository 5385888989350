import Styled from "styled-components";
import style from "styled-components";

const SidebarWrap = Styled.div`
            /*   ------------------------ */
            input.form-control::placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
            input.form-control::-webkit-input-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control::-moz-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control:-ms-input-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control:-moz-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
                .sidebar 
                {
                    position: fixed;
                    top: 0px;
                    left: 0;
                    bottom: 0;
                    width: 160px;
                    z-index: 11;
                    padding: 70px 0 0;
                    background: ${({ theme }) => theme["background-color"]};
                    min-height: calc(100vh - 70px);

                    .nav {
                        &:not(.sub-menu) {
                            padding-bottom: 60px;
                            padding-top: 0px;
                        }

                        overflow: hidden;
                        flex-wrap: nowrap;
                        flex-direction: column;

                        .nav-link {
                            -webkit-font-smoothing: antialiased;
                            font-size: 14px;
                            letter-spacing: 0.2px;
                            color: ${({ theme }) => theme["panel-heading"]};
                            text-shadow: none;
                            text-align: left;
                            padding: 6px 10px 6px 28px;
                            // border-radius: 0 15px 15px 0;
                            font-weight: 500;
                            svg {
                                margin-right: 15px;
                                width: 17px;
                                height: 17px;
                                vertical-align: middle;
                                path 
                                {
                                    fill:${({ theme }) => theme["icon-color"]};
                                }
                            }

                            span {
                                vertical-align: middle;
                            }

                            &:focus,&:hover, &:active,&.active {
                                // color : ${({ theme }) => theme["hover-icon-color"]};
                                // background-color: #e8eaed;
                                background-color: ${({ theme }) => theme["panel-color"]};
                                svg {
                                    path {
                                        fill: ${({ theme }) =>
        theme["hover-icon-color"]};
                                    }
                                }
                            }
                
                        }

                        .dropmenuitems {
                            .nav-link {
                                padding: 6px 10px 6px 60px;
                                font-size: 13px;
                                color: ${({ theme }) => theme["panel-heading"]};
                            }
                            .nav-link.active {
                                font-weight: 500;
                                background-color: transparent;
                                color: ${({ theme }) => theme["orange-color"]};
                            }
                        }

                        .nav-link.active {
                            font-weight: 600;
                        }
                    }

                    li.nav-item {
                        background-color:#181818;
                        list-style: none;
                        margin: 3px 0;
                        .dropmenuitems {
                            ul {
                                padding-left: 0px;
                                .nav-link {
                                    &:focus {
                                        background-color: transparent;
                                        border-radius: 0;
                                    }
                                    &:active {
                                        background-color: transparent;
                                        border-radius: 0;
                                    }
                                    &:hover {
                                        background-color: transparent;
                                        border-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .serchFieldMain {
                    svg {
                        path {
                            fill: ${({ theme }) => theme["icon-color"]};
                        }
                    }
                }

                .sidebarMainLinks {
                    position: absolute;
                    padding: 0px 0px 0;
                    top: 135px;
                    overflow: hidden;
                    overflow-y: auto;
                    min-height: calc(100vh - 70px);
                }
                
                .sidebarMain {
                    .serchFieldMain {
                        padding-top: 10px;
                        margin: 0 27px;
                        svg {
                        position: absolute;
                        top: 26px;
                        left: 0px;
                        width: 14px;
                        height: 14px;
                    }
                    input.form-control {
                        width: 100%;
                        border-radius: 0;
                        border: none;
                        border-bottom: 1px solid #e1e5eb;
                        background-color: transparent;
                        height: 42px;
                        padding-bottom: 0;
                        padding-left: 25px;
                        color: ${({ theme }) => theme["themeColor"]};
                    }
                }
            
                
                .sideBar {
                    .siteLogo {
                        min-height: 90px;
                    }
                    .nav-menu {
                        a {
                            svg {
                                vertical-align: middle;
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }

                .sidebar.backtoMainSideBar {
                    ul.nav {
                        padding-top: 10px;
                    }
                }

                .mobSideBar {
                    .backLinkMain {
                        .backlink {
                            .backTxt {
                                display: none;
                            }
                            padding: 10px 10px 10px 14px;
                        }
                    }
                    .sidebarMain {
                        .serchFieldMain {
                        margin: 0 17px;
                        input.form-control {
                            display: none;
                        }
                    }
                }

                .sidebar {
                    .nav {
                        .dropmenuitems {
                            .nav-link {
                                display: none;
                            }
                        }
                        .nav-link {
                            padding: 6px 10px 6px 17px;
                        }
                    }
                }
                
                .sidebarMainLinks {
                    width: 60px;
                }

                .sidebar.sidebarInerLinks {
                    width: 60px;
                }

                .sidebar.sidebarMain {
                    width: 60px;
                }

                .listArowIcon {
                    display: none;
                }

                .navItemArrow {
                    display: none;
                }

                .nav-link {
                    span.dsktopView {
                    display: none;
                    }
                }

                .main-panel {
                    margin-left: 60px;
                }
                
            }

            .backLinkMain {
                padding: 0;
                text-align: left;
                border-bottom: 1px solid ${({ theme }) => theme["border-color"]};
                .backlink {
                    text-decoration: none;
                    line-height: normal;
                    display: block;
                    padding: 15px 10px 15px 25px;
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    text-shadow: none;
                    text-align: left;
                    font-weight: 500;
                    color: ${({ theme }) => theme["panel-heading"]};
                    background-color: ${({ theme }) => theme["background-color"]};
                   
                    .backTxt {
                        padding-left: 6px;
                        padding-top: 0px;
                        display: inline-block;
                    }
                }
            }

            .backtoMainSideBar {
                position: fixed;
                top: 0px;
                left: 0;
                bottom: 0;
                width: 160px;
                z-index: 11;
                padding: 70px 0 0;
                background: #fff;
                min-height: calc(100vh - 70px);
                left: -260px;
                visibility: hidden;
                transition: all ease-in-out 0.5s;
            }

            .sidebar.sidebarInerLinks {
                position: absolute;
                padding: 0;
                top: 119px;
                overflow: hidden;
                overflow-y: auto;
                min-height: calc(100vh - 70px);
            }

            .backtoMainSideBar.backtoMainSideBarActive {
                left: 0;
                visibility: visible;
                transition: all ease-in-out 0.5s;
            }

            .navItemArrow {
                float: right;
                width: 27px;
                height: 27px;
                margin: 0 auto;
            }

            .mobSideBar {
                .main-panel {
                    margin-left: 60px;
                }
            }


            .main-panel {
                margin-left: 160px;
                display: flex;
                flex-direction: column;
                min-width: 0;
                overflow: hidden;
                min-height: calc(100vh - 70px);
                z-index: 11;
                background: rgba(242, 245, 245, 0.8);
                padding: 15px;
                width: 100%;
                flex-grow: 1;
            }
            @media (max-width: 767px) {
                
            }
            @media (max-width: 575px) {

                .main-panel {
                    margin-left: 0;
                }
                .mobSideBar .main-panel {
                    margin-left: 0;
                }
                .sidebar {
                    z-index: 111;
                    width:0
                }
                .mobSideBar .sidebar.sidebarMain {
                    width: 0;
                }
                .mobSideBar .sidebarMainLinks {
                    width: 0;
                }
                .mobSideBar .sidebarMain .serchFieldMain {
                    display: none;
                }
                .mobSideBar .sidebar.sidebarMain {
                    box-shadow: 0 0 10px #00000020;
                    -webkit-transition: all 0.5s ease-out;
                    width: 160px;
                    z-index: 111;
                }
                
                
            }

`;

const Filterbox = style.div`

        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
        *{
            font-family: Montserrat !important;
        }

        .active{
            margin-top: 10px;
            display: inline-flex;
            justify-content: center;
            width: 250px;
            margin-left: 30px;
            /* transition: 300ms; */
        }

        .container{
            margin-top: 10px;
            background-color: #ffffff;
            display: inline-flex;
            justify-content: center;
            position: absolute;
            left: -320px;
            // transition: 300ms;
            pointer-events: none;
        }

        .menu-icon{
            margin-top: 35px;
            margin-right: 20px;
            margin-left: 10px;
            font-weight: bold;
            font-size: 25px;
            cursor: pointer;
        }
        .filter-box
        {
            position:absolute;
            top:-65px;
            left:0px;
            // width: 300px;
            height: 1050px;
            margin-left: 50px;
            border-right: 2px solid #e1e5eb;
        }   
        .sc-gsDKAQ cNQRpu {
            display: none;
        }
        .filter-N-refine {
            color: var(--orange-color) !important;
        }
        .filter-sidebar {
            position: relative;
            margin-right: 0px;
            width: 100%;
        }
        .filter-box-header {
            margin-top: 30px;
            width: 215px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            font-weight: bold;
        }
        h5{
            margin-left: 2px;
            margin-top: 10px;
            font-weight: bold;
            color: var(--orange-color) !important;
        }
        h6{
            margin-left: 15px;
            font-weight: bold;
            font-size: 16px;
        }
        h4{
            margin-left: 15px;
            font-weight: bold;
            font-size: 18px;  
        }
        .types .form-check-input{
            padding: 4px;
        }
        .states .form-check-input{
            padding: 4px;
        }
        .accordion-button:not(.collapsed) {
            color: #000000;
            background-color: #ffffff;
            border-width: 2px;
        }
        .accordion-button:focus{
            box-shadow: 0 0 0 0 #ffffff;
        }
        .accordion-button {
            height: 10px;
            // width: 282px;
            font-weight: bold;
            background-color: #ffffff;
            box-shadow: 0 0 0 0 #ffffff;
            color: #000000;
            font-size:18px;
            padding-left:0px;
        }
        .accordion-collapse{
            width: 230px;
        }
        .accordion-{
            padding: 10px;
            width: 230px;
            height: 170px;
        }
        .accordion-item{
            border: 0;
            margin-left: -40px;
        }
        .customise_sidebar{
            width:95%;
            margin:auto;
            overflow-x:hidden;
        }
        .customise_sidebar .accordion-item{
            border: 0;
            margin-left: 0px;
        }
        
        .types,.states{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: large;
            font-weight: 500;
            padding:5px;
        }
        .accordion-body1 .accordion-body-content{
            padding: 10px;
            width: 280px;
        }
        .accordion-body1 .accordion-body-content::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px #0000004d;
            box-shadow: 0px;
            background-color: #ffffff;
        }
        .accordion-body1 .accordion-body-content::-webkit-scrollbar
        {
            width: 6px;
            background-color: #F5F5F5;
        }
        .accordion-body1 .accordion-body-content::-webkit-scrollbar-thumb
        {
            background-color: #878383;
        }
        button{
            color: #000000;
        }
        .accordion-button:not(.collapsed)::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            transform: rotate(-180deg);
        }
        .accordion-body {
            padding: 11px;
        }
        .accordion-body1  .form-check-input:checked {
            background-color: #FA9001;
            border-color: #FA9001;
        }
        .accordion-body1 .form-check-input:focus {
            border-color: #FA9001;
            outline: 0;
            box-shadow: inherit;
        }

        @media screen and (max-width: 480px){
            .active{          
                margin-right: 300px;
                position: relative;
                right: 40px;
                bottom: 10px;
            }
            

        }
`
    ;




export { SidebarWrap, Filterbox };
