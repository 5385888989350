import React from 'react';
import NotFoundWrap from './style.js';
import pageNotFoundImg from "../../../assets/images/404.svg"

const PageNotFound = () => {
	return (
		<NotFoundWrap>
			<img className='img' src={pageNotFoundImg} alt="404" />
		</NotFoundWrap>
	)
}

export default PageNotFound;
