import Styled from 'styled-components';

const HeaderWrap = Styled.div`
            .navbar {
                font-weight: 600;
                padding: 0px;
                height: 60px;
            }
            .navbar-brand {
                padding: 2px;
            }
            .z-index-navbar {
                z-index: 100;
            }
            .five-min-timer-note {
                padding-top: calc(60px + 15px);
                background: #FFD800;
                color: #5C3C3C;
                padding-left: 40px;
                padding-bottom: 15px;
                padding-right: 40px;
                font-weight: 600;
            }
            .loginSubmitBtn {
                background-color: #1958A7;
                color: white;
            }
            .profile {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .blinking-dot {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: red;
                animation: blink 1s infinite;
            }
            @keyframes blink {
                0% {
                    opacity: 1;
                }
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
            .profileLetter {
                width: 45px;
                height: 45px;
                line-height: 45px;
                border-radius: 50%;
                background-color: #E4E4E4;
                color: black;
                opacity: .9;
                white-space: nowrap;
                text-align: center;
                align-self: center;
                font-size: 2.4em;
                font-weight: 500;
            }
            .navbar-toggler:focus {
                text-decoration: none; 
                outline: none; 
                box-shadow: 0 0 0 0; 
            }
            .log {
                position: absolute;
                top: 8px;
                right:10px;
            }
            .profile{   
                right:80px;
            }
            .pos {
                position: relative;
                top: 2px;
                z-index: 2;
            }
            .dropdown-toggle::after {
                content: none;
            }
            .infoworks {
                font: normal normal normal 20px/24px Montserrat;
            }
            .accountblack {
                font: normal normal normal 20px/24px Montserrat;
            }
            .nav-link.active {
                padding-bottom: 13px;
                border-bottom: 3px solid var(--orange-color);
                border-radius: 1px;
                transition: 0.1s easeinout;
                margin-bottom: -13px;
            }
            .modal-header {
                justify-content: center !important;
                align-self: center;
            }
            .modal-content {
                width: 50% !important;
                    margin-left:10%;
                    position: relative; 
                    top:50px;
            }
            .close_button {
                width: 100px !important;
            }
            .log_out_button {
                width: 100px !important;
                height: 38px !important;
                background: var(--orange-color);
                border: none;
                color: white;
            }
            .notification {
                 font: normal normal 20px/24px Montserrat;
                 border-bottom: 1px solid black;
                 margin-bottom: 8px;
            }
            .notification-message {
                font: normal normal 18px/20px Montserrat;;
            }
            .dropdown-menu.show {
                max-height: 600px !important;
                overflow: auto;
            }
            a, a:hover, a:visited, a:active {
                color: black;
                text-decoration: none;
            }
            .form-control {
                &:focus {
                    box-shadow: inherit !important;
                }
            }
            .imgfirstLetr {
                display: inline-block;
                background-color: ${({ theme }) => theme['orange-color']};
                width: 32px;
                height: 32px;
                border-radius: 50%;
                color: #fff;
                box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
                font-size: 18px;
                padding: 6px 6px;
                margin: 0 auto;
                text-align: center;
            }
            .bgGreyLetr.imgfirstLetr {
                background-color: ${({ theme }) => theme['gray-color']};
                box-shadow: inherit;
            }
            .imgfirstLetrDropdwnTogle {
                padding: 4px !important;
                border-radius: 50% !important;
                width: 40px;
                height: 40px;
            }
            .navbar-brand
            {   
                width: 100%;
                margin:0 auto;
            }
            .dropdown-toggle.btn-primary {
                color: #fff;
                background-color: transparent;
                border-color: transparent;
            }
            .dropdown-toggle.btn-primary:hover {
                color: #fff;
            }
            .dropdown-toggle.imgfirstLetrDropdwnTogle[aria-expanded="true"] {
                background-color: #5f63681f;
                border-radius: 50%;
                padding: 4px;
                width: 40px;
                height: 40px;
            }
            .dropdown-toggle.imgfirstLetrDropdwnTogle {
            &:hover[aria-expanded="true"] {
                background-color: #5f63681f;
                border-radius: 50%;
                padding: 4px;
                width: 40px;
                height: 40px;
            }
            }
            .profInfo {
            .defultImgFirsLetr {
                vertical-align: top;
                height: 60px;
                width: 70px;
                font-size: 40px;
                padding: 10px;
                margin-bottom: 10px;
            }
            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: normal;
            }
            }
            .singleProfDetail {
                border-bottom: 1px solid #f2f2f2;
                padding: 15px 0;
                 &:last-child {
                 border-bottom: none;
                 }
            }
            .addUserIcon {
            width: 32px;
            height: 32px;
            svg {
                width: 32px;
                height: 32px;
            }
            }
            .anothrAcount {
            margin-top: 10px;
            }
            .dpdwnProfDetail {
            h6 {
                font-weight: 400;
            }
            }
            .singleProfDetail.acountList {
            padding: 0;
            h6 {
                font-weight: 500;
                font-size: 15px;
            }
            p {
                font-size: 12px;
                font-weight: 400;
            }
            small {
                font-size: 11px;
                font-weight: 400;
                color: ${({ theme }) => theme['gray-color']};
            }
            }
            .singleProfListng {
            padding: 10px 15px;
            &:hover {
                background-color: transparent;
            }
            }
            .signOutAcount {
            a.btn {
                font-size: 12px;
                border: 1px solid ${({ theme }) => theme['gray-color']};
                padding: 10px 24px;
                letter-spacing: 0.15px;
                text-align: center;
                &:hover {
                background-color: ${({ theme }) => theme['gray-color']};
                }
            }
            }
            .hoverBg {
            padding: 4px 10px 0 0;
            .dropdown-toggle {
                svg {
                width: 35px;
                height: 35px;
                padding: 6px;
                border-radius: 40px;
                path {
                    fill: ${({ theme }) => theme['icon-color']};
                }

                &:hover {
                    background-color: #f2f2f2;
                    path {
                    fill: ${({ theme }) => theme['hover-icon-color']};
                    }
                }
                &:focus {
                    background-color: #f2f2f2;
                    path {
                    fill: ${({ theme }) => theme['hover-icon-color']};
                    }
                }
                &:active {
                    background-color: #f2f2f2;
                    path {
                    fill: ${({ theme }) => theme['hover-icon-color']};
                    }
                }
                }
            }
            }
            .singleOption {
            padding: 10px 0;
            }
            .selectThemepart {
            .form-label {
                font-size: 12px;
                text-transform: uppercase;
                color: #a1a1a1;
                letter-spacing: 1.4px;
                display: block;
                margin-bottom: 15px;
                padding-bottom: 5px;
            }
            .form-check-label {
                margin-left: 5px;
            }
            .feedbckTxt {
                font-size: 12px;
                color: ${({ theme }) => theme['icon-color']};
                margin-left: 5px;
            }
            .defaultTheme {
                .form-check-input {
                cursor: pointer;
                border: 2px solid #0c0c0c40;
                position: absolute;
                top: 9px;
                }
                .themePreviewimg {
                top: 6px;
                }
            }
            .themePreviewimg {
                position: absolute;
                top: -6px;
                right: 0;
                border: 1px solid #d2d2d2;
                padding: 3px;
                border-radius: 3px;
            }
            }

            #close-button {
                width: 70px
            }
            #yes-button {
                background-color: var(--orange-color) !important;
                color: white;
                margin: 10px;
                width:70px;
                text-align: center;
                padding: 6px;
                margin: 4px;
              }
            #yes-button:hover {
                background-color: #fa6401 !important;
              }

            @media (max-width:500px){
                .bidleadicon{
                    padding-bottom: 16px;
                }
            } 
            @media (max-width:450px){
                .bid-logo{
                    padding-bottom:20px;
                }
            } 

            @media(max-width: 766px) {
                .modal-content {
                    display: block;
                    width: 80% !important;
                    margin-left:10%;
                    position: relative; 
                    justify-content: center !important;
                    align-self: center;
                    top:90px;
                    }
            }

            @media (max-width:767px) {
                .log {
                    position: relative;
                }
                .navbar-nav {
                    float: right ;
                }
                .navbar-collapse {
                    display: none;
                  }
                  .navbar-toggler {
                    display: none;
                  }
                .navbar-collapse {
                    background-color: rgba(41, 40, 120,0.4);
                    width: 200px;
                  }
                  .modal-content {
                    display: block;
                    position: relative;
                    justify-content: center !important;
                    align-self: center;
                }
                  .container-fluid {
                    padding: 0;
                  }
                  .profile{
                        display: none;
                  }
            }

            @media (min-width: 992px) {
            .navbar {
                .navbar-menu-wrapper {
                .topNavListing.topNavListing-right {
                    margin-left: 20px;
                }
                }
            }
            }

            @media (max-width: 576px) {
                .five-min-timer-note {
                    font-size: 0.8rem;
                    padding-top: calc(60px + 5px);
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 5px;
            }
            }
`;

export default HeaderWrap;