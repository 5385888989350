import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
       :root {
        --error-color: #E03C16;
        --orange-color: #FA9001;
        --blue-color: #3A7FF5;
        --green-color: #67BE01;
       }
        body {
          font-family: ${({ theme }) => theme["font-stack"]}!important;
          overflow: hidden;
        }
        h1, h2, h3, h4, h5, h6, 
        .h1, .h2, .h3, .h4, .h5, .h6
        {
            font-family: ${({ theme }) => theme["font-stack"]}!important;
            color: ${({ theme }) => theme["heading"]};
        }
        * {
            &:focus {
                outline: inherit;
            }
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        input::placeholder {
        font-size: 14px ;
        opacity: .5;
        }
        .form-select:focus {
          border-color: #ced4da;
          outline: 0;
          box-shadow: inherit;
        }
      .loginformbody .text-center .btn 
      {
        width: 170px;
      }
      .loginSubmitBtn
      {
        position:relative;
      }
      .max-bid-note {
        font-size: 12px;
        font-weight: 500;
        font: normal normal medium 12px/17px Montserrat;
        text-align: left;
        width: 250px !important;
        padding: 10px !important;
      } 
      .loginSubmitBtn .spinner-grow
      {
        position: absolute;
        left: 10%;
        top: 50%;
        margin-top: -7px;
      }
      .btn 
      {
        &.btn-orange {
            color: #fff;
            background-color: ${({ theme }) => theme["orange-color"]};
            border-color: ${({ theme }) => theme["orange-color"]};
            margin: 0 auto;
            font-size: 16px;
            &:hover {
                color: #fff;
                background-color: ${({ theme }) => theme["orange-hover"]};
            border-color:${({ theme }) => theme["orange-hover"]};
            }
        }
      }
        a {
          color: ${({ theme }) => theme["orange-color"]};
          text-decoration: none;
          &:hover {
              color: ${({ theme }) => theme["orange-hover"]};
              text-decoration: none;
          }
        }
        .btn-close {
            &:focus {
                box-shadow: inherit !important;
            }
        }
        .modal {
          padding-left: 0;
            .modal-content {
                border-radius: 10px;
            }
        }
        .cursor
        {
          cursor : pointer;
        }

        .form-control {
            &:focus {
                box-shadow: none !important;
            }
        }
        .btn-check {
            &:focus {
                + {
                    .btn {
                        box-shadow: none !important;
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
        }
        .btn {
            &:focus {
                box-shadow: none !important;
                outline: 0;
                box-shadow: none;
            }
        }
        button:focus {
            outline: inherit;
        }
        button,
        html,
        input,
        select,
        textarea {
            font-family: ${({ theme }) => theme["font-stack"]}!important;
        }
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        input::-ms-clear,
        input::-ms-reveal {
            display: none;
        }
        input[type="text"]::-ms-clear {
            display: none;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        li {
            list-style-type: none;
        }
        ul {
            padding: 0px;
            margin: 0px;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .form-control input::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: ${({ theme }) => theme["gray-color"]};
        }
        .form-control input::-moz-placeholder {
            /* Firefox 19+ */
            color: ${({ theme }) => theme["gray-color"]};
        }
        .form-control input:-ms-input-placeholder {
            /* IE 10+ */
            color: ${({ theme }) => theme["gray-color"]};
        }
        .form-control input:-moz-placeholder {
            /* Firefox 18- */
            color: ${({ theme }) => theme["gray-color"]};
        }
        input:focus {
            outline: inherit;
        }
        select:focus {
            outline: inherit;
        }
        .btn.focus, .btn:focus {
            outline: inherit;
            box-shadow: inherit;
        }
        .form-control:focus {
          border-color: #ced4da;
        }
        .navbar-dark {
            .top-menu-links {
              color: ${({ theme }) => theme["orange-color"]};
            }
            .navbar-nav {
                .nav-link {
                  color: ${({ theme }) => theme["darkMode-lightColor"]} ;
                  &:active,
                  &:hover,
                  &:focus {
                    color: ${({ theme }) => theme["darkMode-lightColor"]};
                  }
                }
            }
            .dropdown-menu
            {
              padding: 0;
              
              .dropdown-item {
                  padding: 5px 20px;
                  .top-menu-links {
                      color: #212529;
                  }
                  &.active,&:active {
                      background-color: ${({ theme }) => theme["orange-color"]};
                      .top-menu-links {
                          color: ${({ theme }) => theme["darkMode-lightColor"]};
                          background-color: ${({ theme }) => theme["orange-color"]};
                          &.active {
                            color: ${({ theme }) => theme["darkMode-lightColor"]};
                          }
                      }
                  }
              }
            }
          }
          .navbar-light {
            .top-menu-links {
              color: ${({ theme }) => theme["orange-color"]};
            }
            .navbar-nav {
              .nav-link {
                color: #000;
                &:active,
                &:hover,
                &:focus {
                  color: var(--orange-color);
                }
              }
            }
            @media (min-width: 400px){
              .dropdown-menu{
                padding: 10px 45px 15px 10px !important;
              }
            }
            .dropdown-menu{
              box-shadow: 0px 3px 6px #0000004f;
              border: none ;
            }
            .dropdown-header{
              color: #181818 !important;
              font: normal normal bold 20px/24px Montserrat;
            }
            img {
               margin-right: 0px;
            }
            svg{
              margin-right: 0px;
            }
            .dropdown-menu
            {
              padding: 0;
            .dropdown-item {
              padding: 5px 20px;              
              .top-menu-links {
                color: var(--orange-color);
              }
              &.active,
              &:active {
                background-color: ${({ theme }) => theme["orange-color"]};
                .top-menu-links {
                  color: ${({ theme }) => theme["darkMode-lightColor"]};
                  background-color: ${({ theme }) => theme["orange-color"]};
                  &.active {
                    color: ${({ theme }) => theme["darkMode-lightColor"]};
                  }
                }
              }
            }
          }
          }
          .darkmode {
            .sidebar {
              .nav {
                .dropmenuitems {
                  .nav-link {
                    color: ${({ theme }) => theme["darkMode-lightColor"]};
                  }
                }
              }
              .backtoMainSideBar {
                background: #000000;
                .backLinkMain {
                  .backlink {
                    color: ${({ theme }) => theme["darkMode-lightColor"]};
                    svg {
                      path {
                        fill: ${({ theme }) => theme["darkMode-lightColor"]};
                      }
                    }
                    &:hover {
                      color: ${({ theme }) => theme["orange-color"]};
                      background-color: transparent;
                      svg {
                        path {
                          fill: ${({ theme }) => theme["orange-color"]};
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .nav-tabs {
            border-bottom: inherit;
            .nav-link {
              color: ${({ theme }) => theme["tabLink"]};
              background-color: ${({ theme }) => theme["tabBg"]};
              border-color: transparent transparent ${({ theme }) => theme["tabLink"]} ;
              &.active {
                 color:  ${({ theme }) => theme["tabLink"]};
                 background-color: ${({ theme }) => theme["tabBg"]};
                 border-color: ${({ theme }) => theme["tabLink"]} ${({ theme }) => theme["tabLink"]} transparent;
              }
            }
          }
          .tab-content .tab-pane.active
          {
            color:  ${({ theme }) => theme["panel-heading"]};
          }
          .alert-success{
            background-color : var(--green-color);
            border: 1px solid var(--orange-color);
            color : #fff;
          }
`;
