import styled from "styled-components";

export const MaxBidRemainderWrap = styled.div`
.remainder { 
    text-align: center;
    font: normal normal 600 18px/27px Montserrat;
    letter-spacing: -0.36px;
    color: #000000;
    font-weight: 500;
    padding: 0px 20px 20px 20px;
}
.set-bid-button {
    background: #FA9001; 
    border: none;
    color: white;
    font: normal normal 600 18px/22px Montserrat;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 10px;
}
.maxBidRemainder_modal_header {
    border-bottom: none;
}
.modal-body {
    padding: 0px 16px 16px 16px;
}

`;