import React from 'react'
import { Button, Container, } from 'react-bootstrap'
import { MaxBidRemainderWrap } from './style'
import UIModal from '..'

const MaxBidRemainder = ({ show, onHide, setBid }) => {
    return (
        <UIModal show={show} onHide={onHide} size="lg">

            <Container>
                <MaxBidRemainderWrap>
                    <div className='remainder'>
                        Please note that it can take up to 20 seconds to remove your maximum bid once it's placed. The auto bidder may continue to place bids up to your maximum bid during that time.
                    </div>
                </MaxBidRemainderWrap>
                <MaxBidRemainderWrap className='d-flex justify-content-center align-items-center'>
                    <Button
                        className='set-bid-button'
                        id='set-bid'
                        onClick={() => { setBid() }}
                    >
                        Set Bid
                    </Button>
                </MaxBidRemainderWrap>
            </Container>

        </UIModal>
    )
}

export default MaxBidRemainder