import styled from "styled-components";

const NotFoundWrap = styled.div`

background-image: url("/image/404bg.svg");
height: 100vh;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
justify-content: center;
align-items: center;

.img {
	width: auto;
	height: auto;
}


`;

export default NotFoundWrap;
