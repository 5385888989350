import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import User from "../../../assets/images/UICardImages/User_Profile.jpg";

const AuctionHistoryCardWrap = styled.div`
* {
    white-space: pre-wrap;
}
.card {
    background: ${props => props.grey ? "#EBEBEB" : "#F7F7F7"};
    border-radius: 5px;
}
.full-card-row{
    justify-content: space-around;
    box-shadow: 0px 0px 8px #0000003b;
    border-radius: 5px;
    opacity: 1;
}
.app-id-row{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding-top: 6px;
    border-bottom: 1px inset #E4E4E4;
    background: ${props => props.grey ? "#EBEBEB" : "#F7F7F7"};
    position: relative;
    padding-bottom: 10px; 
}

.app-id-row::before {
    content: "";
    background: inherit;
    position: absolute;
    bottom: -10px; 
    left: 0;
    height: 20px;
    width: 40px;
}
.app-id-row::after {
    content: "";
    background: inherit;
    position: absolute;
    bottom: -10px; 
    right: 0;
    height: 20px;
    width: 40px;
}
.card-label {
    font-size: 1.4rem;
    font-weight: 600;
}
.loan-amount{
    text-align: end;
}
.loan-amount-color {
    color: var(--orange-color);
}
.app-head-row {
    --bs-gutter-y: .5rem;
}
.avatar{
    display: contents;
}
.user-img{
    height: 65px;
    width: 65px;
    opacity: 0.78;
    object-fit: cover;
}
hr {
    margin: 1px;
}
.app-details-row{
    align-items: center;
    margin-bottom:10px;
    flex-wrap: nowrap;
    text-align:left;
}
.app-id-span{
    font: normal normal normal 24px/29px Montserrat;
    margin-left:10px;
    text-align:left;;
}
.hide-hr {
    display: none;
}
.you-won {
    text-align: right;
    font-weight: bold;
    font-size: 22px;
    padding-right: 50px;
    color: #FFCB1C;
}
.won {
    color: #67BE01;
    font-weight: 800;
}
.not_won {
    color: var(--orange-color);
    font-weight: 800;
}

@media only screen and (max-width: 575px){ 
.application {
    padding: 7px;
}
.ui-card-dashboard{
    font-family: 'Montserrat', sans-serif;
    padding: 0 !important;
}
.app-id-span{
    text-align: left;
}

.app-id-row, .card-label, .app-id-span{
    font-size: 20px !important;
    flex-direction: column;
    justify-content: center; 
}
.you-won {    
    text-align: center;
    padding-right: 0;
}
.app-id-row {
    text-align: center;
}
.app-details-row {
    text-align: center;
}
.loan-amount {
    text-align: center;
}

}

`

const AuctionHistoryCard = ({ won, data }) => {

    const wonColor = won ? "won" : "not_won"

    return (
        <AuctionHistoryCardWrap grey={won}>
            <Container fluid className="mb-4 ui-card-dashboard margin-auto">
                <Row>

                    <Card>
                        <Card.Body className="p-0">
                            <Row className="full-card-row">

                                <Row className="app-id-row">
                                    <Col
                                        className="avatar mb-2"
                                    >
                                        <img
                                            className="user-img text-center p-1" alt="User" src={User} />
                                    </Col>

                                    <Col className='flex-fill'>
                                        <div className="card-label">Application ID: {data.application_id}  |  Loan Amount: {(data.loan_amount === "" ? "no-data" : `$${parseInt(data.loan_amount).toLocaleString("en-US")}`)}</div>
                                    </Col>

                                    {won && <Col className='you-won ps-0'>You Won</Col>}
                                </Row>

                                <Row>

                                    <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center p-4 px-5'>
                                        {data.winning_bid !== null && <div className={`fs-5 text-center ${wonColor}`}>Winning bid: {(data.winning_bid === "" ? "no-data" : `$${parseInt(data.winning_bid).toLocaleString("en-US")}`)}</div>}
                                        {!won ? (data.won_by !== null && <div className='text-center fw-bold' >Won By: {data.won_by}</div>) : <div></div>}
                                    </div>
                                </Row>
                            </Row>
                        </Card.Body>

                    </Card>

                </Row>
            </Container>

        </AuctionHistoryCardWrap >
    )
}

export default AuctionHistoryCard