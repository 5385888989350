import React, { useEffect } from 'react'
import { Button, Row, Col, Container, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import UIModal from '..'

const StyleWrap = styled.div`
input[type="text"] {
    background-color: #EBEBEB;
}
.placeBid-form {
    width: 100px; 
    color: var(--orange-color); 
    border: none;
}
.oragne_button {
    width: 120px;
    background: var(--orange-color);
    border: none;
    color: white;
}
`
const ConfirmModal = ({ ...props }) => {
    const schema = yup.object().shape({
        bidAmount: yup.number("Enter Number")
            .positive("Enter Positive Number")
            .integer()
            .required("Amount is Required")
            .typeError('Amount is required')
    })

    const initialValue = props.modalData.your_amount

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            bidAmount: ""
        },
        resolver: yupResolver(schema),
        shouldUnregister: true,
    })

    useEffect(() => {
        setValue("bidAmount", initialValue)
    }, [props.modalData, setValue, initialValue])

    const normalizeNumber = (value) => {
        return value.replace(/[^0-9]/g, '')
    }

    const onSubmit = (data) => {
        props.postAmount(parseInt(data.bidAmount))
        props.setOpenConfirmModal(false)
    }
    return (
        <StyleWrap>
            <UIModal
                {...props}
                size="md"
                closeButon={false}
            >
                <Container className='text-center pt-2 pb-0'>
                    <Row>
                        <Col className='fw-bold fs-5 m-2'>
                            Please confirm your bid
                        </Col>
                    </Row>
                    <Row>
                        <Col className='m-2'>
                            <Form noValidate className='form-alignment mx-3 p-1' onSubmit={handleSubmit(onSubmit)}>
                                <StyleWrap>
                                    <Form.Control
                                        className="placeBid-form p-1 m-auto text-center fw-bold"
                                        type="text"
                                        placeholder={"$0"}
                                        inputMode="numeric"
                                        name="bidAmount"
                                        id="bidAmount"
                                        maxlength="3"
                                        {...register("bidAmount", {
                                            onChange: (event) => {
                                                const { value } = event.target
                                                event.target.value = normalizeNumber(value);

                                            }
                                        })}
                                        isInvalid={errors.bidAmount}
                                        onFocus={(e) => e.target.placeholder = ""}
                                    />
                                </StyleWrap>
                                {errors.bidAmount?.message &&
                                    <Form.Control.Feedback type="invalid" className='error-form'>
                                        {errors.bidAmount?.message}
                                    </Form.Control.Feedback>}

                                <Row>
                                    <Col className='m-2'>Current Bid : ${props.modalData.current_bid}
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <StyleWrap>
                                        <Button
                                            className='oragne_button p-2 mt-3 mx-4 fs-6'
                                            id='cancel'
                                            size='sm'
                                            onClick={() => { props.setOpenConfirmModal(false) }}
                                        >
                                            Cancel
                                        </Button>
                                    </StyleWrap>
                                    <StyleWrap>

                                        <Button
                                            className='oragne_button p-2 mt-3 mx-4 fs-6'
                                            id='confirm'
                                            size='sm'
                                            type="submit"
                                        >
                                            Place Bid
                                        </Button>
                                    </StyleWrap>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </UIModal>
        </StyleWrap>
    )
}

export default React.memo(ConfirmModal)