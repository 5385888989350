import React from "react";
import HowItWorksWrap from "./style";
import { Row, Col } from "react-bootstrap";
import howItWorks from "./how_it_works.json";
import faq from "./faq.json"

const HowItWorks = () => {
  return (
    <HowItWorksWrap>
      <div className='contents'>
        <h5>How it works</h5>
      </div>
      <div className="card1 contents" >
        {
          howItWorks.map(record => {
            return (
              <div key={`how-it-works-${record.id}`}>
                <Row>
                  <Col md={8} className="padder">
                    <div className="main-content">
                      <div className="contents_heading">
                        <h6>{record.title}</h6>
                      </div>
                      <p className="content">
                        {record.content}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
      </div>

      <div className='contents pt-5'>
        <h5>FAQ</h5>
      </div>
      <div className="card1 contents" >
        {
          faq.map(record => {
            return (
              <div key={`how-it-works-${record.id}`}>
                <Row>
                  <Col md={8} className="padder">
                    <div className="main-content">
                      <div className="contents_heading">
                        <h6>{record.title}</h6>
                      </div>
                      <p className="content">
                        {record.content}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
      </div>
    </HowItWorksWrap>
  );

}
export default HowItWorks;
