import React from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import TableWrap from './style';
import Loading from "../Loading/Loading";

const Tables = ({ props }) => {

  const { loading = false, pagination = { paginate: false, number: 0 }, currentPagination = 0, length, setPagination, extra,
    columns, tbody, tableClass = "common_table", headerClass = "common_header", headerNoWrap, header, bordered = false } = props;

  const totalPagination = Math.floor((length - 1) / pagination.number)

  const handleNext = () => {
    if (currentPagination === totalPagination) return
    setPagination(prev => prev + 1)
  }

  const handlePrevious = () => {
    if (currentPagination === 0) return
    setPagination(prev => prev - 1)
  }

  return (
    <TableWrap>
      {loading ?
        <Loading />
        :
        <Card className="border-0 mb-3 table-view-card">
          <Card.Body id="my-table">
            <Row>
              <Col sm={12} className="mb-2 ps-0 py-1">
                <div className={headerClass}>{header}</div>
              </Col>
              {extra ? <Col sm={12} className="mb-2"> {extra} </Col> : <></>}
              <div className={tableClass} >
                <Col md={12}>
                  <Table className="mb-0" striped responsive bordered={bordered} hover size="sm md lg xl xxl">
                    <thead>
                      <tr>{columns
                        .map((item, i) => { return <th className={`p-2 ${headerNoWrap ? "text-nowrap" : ""}`} key={i}>{item}</th> })
                      }</tr>
                    </thead>
                    {tbody}
                  </Table>
                </Col>
              </div>

              {pagination.paginate || length >= pagination.number ?
                <div className="pagination">
                  <div className={`previous ${currentPagination === 0 ? "disabled" : ""}`} onClick={handlePrevious}>Previous</div>
                  <div className={`next ${currentPagination === totalPagination ? "disabled" : ""}`} onClick={handleNext}>Next</div>
                </div>
                : <></>}
            </Row>
          </Card.Body>
        </Card>}
    </TableWrap>
  );
};
export default Tables;
