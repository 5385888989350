import Styled from 'styled-components';

const DashboardView = Styled.div`
   .member-list{
      overflow: auto;
      height: 400px;
      overflow-x: hidden;
   }
   .project-list {
      overflow: auto;
      height: 400px;
      overflow-x: hidden;
   }
   .results, .bid-amount{
      font: normal normal 600 30px/37px Montserrat;
   }

   .live-auction{
       font: normal normal 600 49px/59px Montserrat; 
       color: var(--orange-color);
   }
   p {
      margin-bottom: .5rem;
   }
   .results-col-right {
      text-align: center;
      padding-top: 8px;
   }
   .live-auction-heading {
      font: normal normal 600 42px Montserrat;
      color: var(--orange-color);
   }

   .bid-credit{
      fontSize: 14px;
      display: flex;
      justify-content: end;
      padding-top: 8px;
   }     
   .firstLetr{
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
        font-size: 12px;
        padding: 4px 6px;
        text-align: center;
   }  
   .box {
      background: #C2C2C2 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000022;
      color: black;
      width:70px;
      font: normal normal 600 19px/23px Montserrat;
      padding:8px 0;
   }
   .note {
      font: normal normal 500 14px/17px Montserrat;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 0px 10px 0px 10px;
      width: 500px;
      margin-left: auto;
      margin-right: 10px;
      border-radius: 10px;
   }
   .note-p {
      text-align: left;
      align-self: end;
   }
   .time-remaining {
      display: flex;
      justify-content: end;
      margin-right: 20px;
      font-size: 1.1em;
      font-weight: 500;
      color: black;
      padding-bottom: 3px;

   }
   .min, .sec {
      font-size: .6em;
      font-weight: 400;
   }
   .semi-colon {
      position: absolute;
      right: -15px;
   }
   .project-color-block{
       height : 80px;
       width : 80px;
       border-radius: 20%;
       margin:auto;
       color:white;
       font-size:22px;
   }
   @media only screen and (max-width: 575px){ 

      .time-remaining {
         margin-right: 20px;
      }
      .results-col , .results-col-right{
         text-align:center;
      }      
      .live-auction-heading {
         font-size: 38px ;
      }
      .note {
         width: 100%;
         margin-top: 10px;
         margin-bottom: 10px;
         margin-left: auto;
         margin-right: auto;
      }
      
   }
   @media only screen and (min-width: 768px){ 
      .results{
         padding-left:10px;
      }
      .bid-credit{
         padding-right:10px;
      }
   }
  
   @media (max-width: 780px) {
      .box{
         width:70px;
         font: normal normal 600 16px/23px Montserrat;
         padding:2px;
      }
      .bid-credit, .time-remaining {
         justify-content: center;

      }
      .semi-colon {
         display: none;
      }
   }
   
   @media only screen and (max-width: 780px){
      .live-auction{
         font: normal normal 600 29px/39px Montserrat; 
         margin-left:-25px;
         padding-left: 0px;
     }
   }
  
`;

export default DashboardView;
