import React, { useState, useEffect } from 'react'
import AlertNoInternet from './AlertNoInternet';
import './style.css';
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { isOnlineContext } from "../../../App";

const NoInternet = () => {
    const [show, setShow] = useState(false);
    const { online } = useContext(isOnlineContext);

    useEffect(() => {
        online ? setShow(false) : setShow(true)
    }, [online])

    return (
        <>
            <div className='NoInternetAlert'>
                {show ? <AlertNoInternet /> : <></>}
            </div>
            <Outlet />
        </>
    )
}

export default NoInternet
