import React, { useEffect, useState } from "react";
import Tables from "../../Reuseable/Tables";
import moment from 'moment';
import NoDataFound from "../../Reuseable/ErrorPage/NoDataFound";
import Loading from "../../Reuseable/Loading/Loading";
import { Button, Container, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { useOutletContext } from "react-router-dom";
import { useFetch } from "../../../Hooks/useFetch";

const BidHistoryWrap = styled.div`
.container {
    width: 85%;
}
.bid_history_margin_top {
    margin-top: 35px;
}
.refresh_button {
  width: 150px;
  background: var(--orange-color);
  border: none;
  color: white;
}
.spinner {
  width: 1.125rem;
  height: 1.125rem;
}
.status {
  padding-top: 10px;
  font-weight: 500;
}
.status .won {
  color: hsla(107, 100%, 37%, 1);
}
.status .on_going {
  color: hsla(35, 90%, 50%, 1);
}
.status .out_bid {
  color: hsla(0, 100%, 50%, 1);
}

@media (max-width: 768px) {
  .container {
    width: 100% !important;
    margin: 0;
    margin-right: 0;
    padding: 0;
  }
}
`

const Won = <p className="status won">Won</p>
const Ongoing = <p className="status on_going">Ongoing</p>
const OutBid = <p className="status out_bid">Out Bid</p>

const columns = ['Application ID', 'Date', 'Amount', 'Status']
const extra = null;
const header = "Bid History"

const BidHistory = () => {

  const { bidHistoryFilters, isDataInBidHistory } = useOutletContext()
  const [filteredData, setFilterdData] = useState([])
  const [currentPagination, setCurrentPagination] = useState(0)
  const pagination = { paginate: true, number: 10 }

  const splitArray = (array, size) => {
    const arrayOfArrays = []
    for (let i = 0; i < array?.length; i += size) {
      arrayOfArrays.push(array?.slice(i, i + size))
    }
    return arrayOfArrays
  }

  const paginatedData = splitArray(filteredData, pagination.number)

  const onSuccess = () => {
    isDataInBidHistory(true)
  }

  const onError = (error) => {
    isDataInBidHistory(false)
  }

  const { data: tableData, refetch, isLoading, isFetching } = useFetch("getBidHistoryTableData", "/bid-leads/broker-bidding-history", onSuccess, onError, false, true)

  useEffect(() => {
    let filteredValue = tableData?.filter(({ status }) => {
      return bidHistoryFilters.includes(status)
    })
    setFilterdData(filteredValue?.length === 0 ? tableData : filteredValue)
    setCurrentPagination(0)
  }, [tableData, bidHistoryFilters])

  useEffect(() => {

    const handleFilteringProcess = () => {
      let filteredValue = tableData?.filter(({ status }) => {
        return bidHistoryFilters.includes(status)
      })

      setFilterdData(filteredValue)
    }

    bidHistoryFilters.length !== 0 ? handleFilteringProcess() : setFilterdData(tableData)
    setCurrentPagination(0)
  }, [bidHistoryFilters, tableData])

  const tbody = <tbody>
    {paginatedData.length > 0 && paginatedData[currentPagination]?.map((row, index) => (
      <tr key={index}>
        <td>{row.application_id}</td>
        <td>{
          moment(
            row.bid_date, 'DD/MM/YYYY', true
          ).format('DD/MM/YYYY')
        }</td>
        <td>${row.bid_amount}</td>
        <td>{(row.status === "lost" ? OutBid : (row.status === "won" ? Won : Ongoing))}</td>
      </tr>
    ))}
  </tbody>

  const tableProps = { loading: isLoading, pagination, currentPagination, length: filteredData?.length, setPagination: setCurrentPagination, header, extra, columns, tbody, bordered: true }

  return (
    isLoading ? <Container className='mt-5' > <Loading /> </Container> : (tableData?.length === 0 || tableData?.length === null || tableData === undefined) ?
      <Container className='mt-5' > <NoDataFound /> </Container> : (
        (
          <BidHistoryWrap>
            <Container>
              <div className="bid_history_margin_top">
                <Button
                  className='p-1 mb-4 fw-bold ms-1 refresh_button'
                  onClick={refetch}
                >
                  {isFetching ? <Spinner animation="border" variant="light" className="spinner" /> : "Refresh"}
                </Button>

                <Tables props={(tableProps)} />

              </div>
            </Container>
          </BidHistoryWrap>
        )
      )
  )
}

export default BidHistory;    
