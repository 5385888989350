import styled from "styled-components";

export const TermsNConditionsWrapper = styled.div`

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
font-family: 'Lato', sans-serif;
font-size: 18px;
.termsNconditions-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2em;
    font-weight: bold;
    color: var(--orange-color)
}
.card-view {
    width: 600px;
    height: 600px;
}
.contents {
    margin: 25px;
}
.welcome-content {
    font-size: 1.2em;
    font-weight: 600;
    padding-bottom: 10px;
}
.preface-content {
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 10px;
}
.conditions {
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 7px;
}
 .note {
    padding-top: 10px;
    font-size: 1em;
    font-weight: 600;
 }
 .agreement-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
 }
 .confirm-button {
    margin-left: 10px;
    background-color: var(--orange-color);
    border: none;
    width: 100px;
 }
 .termscondition_checkbox .form-check-input:checked{
    background-color: var(--orange-color);
    border-color: var(--orange-color);
 }

`