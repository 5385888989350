import React from 'react'
import UIModal from '../../../Reuseable/UIModal'

const ViewOnlyRemainder = ({ show, onHide, content }) => {
    return (
        <UIModal show={show} onHide={onHide}>
            {content}
        </UIModal>
    )
}

export default ViewOnlyRemainder