import { ActionTypes } from "./actions";


const intialState = {
    dashBoardData: []
};

export const DataReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_DASHBOARD_DATA:
            return {
                ...state,
                dashBoardData: payload
            }

        default:
            return state;
    }
}