import Styled from "styled-components";
import slide1 from "../../../assets/images/slide1.svg";
import slide2 from "../../../assets/images/slide2.svg";

const LoginWrap = Styled.div`        
        *,*::before,*::after {
            box-sizing: border-box;
        }
        .container-width {
            float: right;
            width: 540px;
            box-shadow: 0px 0px 8px #0000004F;
            border-radius: 10px;
            opacity: 1;
        }
        .loginFormScreens {
            border-radius: 10px;
        }
        .logoimg {
            max-height: 50px;
        }
        .formScreenUser {
            color: #111247;
            font-weight: 600;
        }
        .formScreenTitle {
            color: #555;
            font-weight: 600;
        }
        .forgotLink {
            color:  ${({ theme }) => theme["orange-color"]};
            font-size: 14px;
            font-weight: 500;
            &:hover {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:active {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:focus {
                color: ${({ theme }) => theme["orange-hover"]};
            }
        }
        .loginFormScreens {
            .form-control {
                padding: 10px 20px;
                font-size: 15px;
            }
        }
        form {
            label {
                color: ${({ theme }) => theme["gray-color"]};
            }
        }
        .singleSlide {
            margin: 0;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            position: relative;
        }
        .slide1 {
            background-image: url(${slide1});
            height: 100vh;
        }
        .slide2 {
            background-image: url(${slide2});
            height: 100vh;
        }
        .slideOverlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #4b58614d;
        }
        .loginScreenSlides {
            .slick-dots {
                position: absolute;
                bottom: 56px;
                display: block;
                padding: 0;
                margin: 0 auto;
                list-style: none;
                text-align: center;
                bottom: 16px;
                li {
                    button {
                        font-size: 0;
                        line-height: 0;
                        display: block;
                        width: 11px;
                        height: 11px;
                        padding: 5px;
                        cursor: pointer;
                        color: transparent;
                        border: 0;
                        outline: none;
                        background: #fff;
                        border-radius: 50%;
                        &:before {
                            color: transparent !important;
                        }
                    }
                }
                li.slick-active {
                    button {
                        background: ${({ theme }) => theme["orange-color"]};
                    }
                }
            }
        }
        .loginScreenSlides.slick-dots {
            li.slick-active {
                button {
                    &:before {
                        color: transparent !important;
                    }
                }
            }
        }   

        @media only screen and (min-width:1024px){
            .container-width {
                position: relative;
                left: 20%;
            }
        }
    
        @media only screen and (max-width:800px) {
            .container-width {
                float: none;
                display: flex;
                justify-content: center !important;
                width: 320px;
            }
            .loginPageContent {
                min-width: 350px;
            }
        }

        @media only screen and (max-width:350px) {
            .loginPageContent {
                min-width: 320px !important;
            }
            .container-width {              
                width: 300px !important;        
            }
            .card {
                padding: 0px !important;
            }
        }
`;

export default LoginWrap;
