import Styled from 'styled-components';

const ProfileWrap = Styled.div`
    .gradientblock{
        position: relative;
        display: block;
        margin-top: -15px;
        min-width: 1920px;
        height: 130px;
        background: transparent linear-gradient(97deg, #FC4A1A 0%, #F7B733 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
    }    
    .user-container{
        position: relative;
        width: 900px;
        height: auto;
        margin: auto;
        padding: 0;
        background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
        box-shadow: rgb(0 0 0 / 32%) 0px 0px 8px;
        border-radius: 5px;
        opacity: 1;
        display: flex;
    }
    .user-notification-heading {
        width: 900px;
        padding: 0;
        color: var(--orange-color);
    }
    .data-col{
        display:flex;
        justify-content:center;
    }   
    .underline{
        width: 400px;
        border: 1px solid #E4E4E4;
        opacity: 1;
    }
    .user-row {
        margin-top: 30px;
    }
    .profile {
        width: 180px;
        height: 180px;
        background-color: var(--orange-color);
        border: 4px solid white;
        border-radius: 5px;
        color: white;
        position: absolute;
        top: 40%;
        left: 40px;
        display:flex;
        justify-content:center;
        align-items: center;
    }
    .name-col {
        position: absolute;
        top: 70%;
        left: 12%;
    }
    .profileLetter {
        font-size: 5em;
    }
    .MuiSvgIcon-root.person_icon {
        color: #ffff;
        marginRight: 0px;
        width: 100px;
        height: 100px;
    }
    .MuiSvgIcon-root.check_box_icon {
        color: #00E52C;
    }
    .snackbar {
        padding-top: 100px;
    }
    .profile-data {
        font-weight: 500 !important;
    }
    .notification-preference {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form-check-input[type=checkbox] {
        border-radius: 999px;
    }
    .form-check-input {
        cursor: pointer;
        border: 1px solid #0c0c0c40;
    }
    .form-switch .form-check-input {
        width: 1.8rem;
    }

    @media (max-width:1400px) {
        .user-container{
            width:600px;
        }
        .user-notification-heading {
            width: 500px;
        }
        .details-row{
            margin-left:20px;
        }
    }

    @media (max-width: 1200px) {
        .user-container {
            width: 550px !important;
        }
    }

    @media (min-width:1024px) {    
        .user-details-row {
            margin-left: 30px;
        }
        .user-row {
            margin-bottom: -60px;
        }       
    }

    @media (max-width:1023px) { 
        .user-row{
            margin-bottom:15px;
        }
    }

    @media (max-width:850px) {
        .profile {
            width: 100px;
            height: 100px;
        }
        .name-col {
            left: 8%;
        }
    }

    @media (min-width:500px) and (max-width:800px) {
        .gradientblock{
            margin: 0;
            height: 90px;
        }
        .profile {
            width: 100px;
            height: 100px;
        }
        .user-container{
            margin-top: 30px;
            width: 480px;
        }  
        .user-notification-heading {
            width: 480px;
        }
        .position-relative {
            top: 15%;
        }
        .profile-data{
            padding-left:0px !important;
            padding-right:0px !important;
        }
    }

    @media (max-width:500px) {
        .image-col {
            display:flex;
            justify-content:center;
        } 
        .user-row {
            margin-top: 0px;
        }
        .profile {
            width: 100px;
            height: 100px;
        }
        .gradientblock{
            margin: 0;
            height: 90px;
        }
        .image-col {
            height: 80px;
        }
        .user_name {
            margin: 20px 0px 0px 0px;
        }
        .user-container{
            margin-top: 20px;
            width: 335px !important;
        }
        .user-notification-heading {
            width: 335px !important;
        }
        .underline{
            width: 240px;
        }
        .profile-data{
            padding-left:0px !important;
            padding-right:0px !important;
        }	
    }

    @media (max-width:350px) {
        .user-container{            
            width: 300px !important; 
        }
        .user-notification-heading {
            width: 300px !important;
        }
    }

`;

export default ProfileWrap;