import styled from "styled-components";

export const ReleaseNotesWrapper = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
font-family: 'Lato', sans-serif;
font-size: 18px;
.termsNconditions-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2em;
    font-weight: bold;
    color: var(--orange-color)
}
.card-view {
    width: 600px;
    height: 600px;
}
.contents {
    margin: 0px 25px 25px 25px;
}
.welcome-content {
    font-size: 1.2em;
    font-weight: 600;
    padding-bottom: 10px;
}
.preface-content {
    font-size: 1em;
    font-weight: 500;
}
.conditions {
    font-size: 1em;
    font-weight: 500;
    padding-bottom: 7px;
}
.title {
    font-weight: 600;
}
li.sub_content_how_it_works {
    list-style-type: decimal;
    margin-left: 20px;
}
li.sub_content_important_things {
    margin-left: 20px;
    list-style-type: disc;
}
.note {
    padding-top: 10px;
    font-size: 1em;
    font-weight: 600;
}
.agreement-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}
.confirm-button {
    margin-left: 10px;
    background-color: var(--orange-color);
    border: none;
}
.termscondition_checkbox .form-check-input:checked{
    background-color: var(--orange-color);
    border-color: var(--orange-color);
}

`