import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertNoInternet = () => {
    return (
        <>
            <Alert variant="danger">
                <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                <p>
                    No Internet Connection, Check your Connection and try again
                </p>
            </Alert>
        </>
    )
}

export default AlertNoInternet;