import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import NoAuctionRunningImage from '../../../assets/images/icons/Mask Group 12@2x.png'
import "bootstrap/dist/css/bootstrap.min.css"



const NoAuctionRunningWrap = styled.div`

.space-from-left {
        padding-left: 30px;
    }

.no-auction-box-msg {
    width: 100%;
    height: auto;
    background: #EAF3FA 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px;
}

.message {
    font: normal normal 600 22px/37px Montserrat;
    letter-spacing: 0px;
    color: #131313;
}

@media (max-width: 768px) {
    .container {
        width: 100%;
        padding: 0;
    }
}

@media (max-width: 576px) {
    .space-from-left {
        padding-left: 10px;
    }
}

@media (max-width: 370px) {

    .customer-care-link {
        font-size: 0.8em;
    }
}


`

const NoAuctionRunning = ({ needImage }) => {
    return (
        <NoAuctionRunningWrap>

            <Container className='d-flex-row justify-content-center align-items-center mt-3 mb-4'>
                <div className='no-auction-box-msg pb-5'>
                    <div className='pt-4 space-from-left message'>
                        Sorry, there are no auctions currently running.
                    </div>

                    <div className='pt-2 space-from-left fs-5'>
                        But don’t worry, we’ll let you know as soon as the next one starts.
                    </div>


                    <div className='pt-3 space-from-left'>
                        <span className='fw-bold'>Questions?</span> Get in touch with our team on <span style={{ color: "#FA9001" }}>
                            <a className='text-nowrap' href="tel:1800 444 744">1800 444 744</a>
                        </span> or
                        <br></br>

                        <div className='customer-care-link' style={{ color: "#FA9001" }}>
                            <span className='text-black'>at</span> <a href="mailto:customercare@hashching.com.au">customercare@hashching.com.au</a>
                        </div>
                    </div>

                    <div className='pt-3 space-from-left'>
                        <div className='fw-bold' style={{ color: "#FA9001", textDecoration: "none" }} to='#'>
                            <a href="https://www.hashching.com.au/broker-login">Go back to broker dashboard
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                </svg>
                            </a>
                            <span>

                            </span>
                        </div>
                    </div>


                    {needImage && <div className='d-flex justify-content-end'>
                        <img src={NoAuctionRunningImage}
                            style={{ width: "400px", marginRight: "50px", objectFit: "cover", minWidth: "100px" }}
                            alt='noAuctionRunning' loading='lazy' />
                    </div>}

                </div>

            </Container>

        </NoAuctionRunningWrap>
    )
}

export default NoAuctionRunning